<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingMenu></SettingMenu>
                <div class="setting-content">

                    <template v-if="hasPackagePermissionRemoteInterview">
                        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                            <div class="back-button mt-0">
                                <router-link class="back-button__icon" :to="{name : 'company.setting.email'}">
                                    <i class="eicon e-back"></i>
                                </router-link>
                                <div class="back-button__text">
                                    {{ $t(`Email Setup`) }}
                                    <v-popover offset="10" :disabled="false" style="display: initial;">
                                        <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                                        <template slot="popover">
                                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                            <div style="text-align:left;">
                                                <a v-close-popover href="https://easy.jobs/docs/how-to-setup-email-smtp/"
                                                   target="_blank">
                                                    {{ $t(`How To Setup Email SMTP?`) }}
                                                </a>
                                            </div>
                                        </template>
                                    </v-popover>
                                </div>
                            </div>
                        </div>
                        <div class="form-box feature-image-box">
                            <div class="row form-group">
                                <div class="col-md-6">
                                    <label>{{ $t(`Company Sender Email`) }}</label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            maxlength="100"
                                            :placeholder="$t(`Your Company Email`)"
                                            v-model.trim="form.sender_email"
                                    />
                                    <p v-if="contentLoading && !form.sender_email" class="form-note">
                                        {{ $t('Note: By default email is sent form') }} <pre>no-reply@easy.jobs</pre>.
                                    </p>
                                    <div class="error-message" v-if="errors.sender_email">{{ $t(errors.sender_email) }}</div>
                                </div>

                                <div class="col-4">
                                    <label>&nbsp;</label>
                                    <button class="button info-button" @click="saveSenderEmail">{{ $t('Save') }}</button>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div>
                        <div class="section-title-wrap mb-0 mt-3 d-flex justify-content-between align-items-start">
                            <div class="section-title margin-deliver">{{ $t(`SMTP setup`) }}</div>
                        </div>

                        <div class="form-box feature-image-box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t(`Host`) }}*</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            maxlength="100"
                                            :placeholder="$t(`Host`)"
                                            v-model="smtp.host"
                                        />
                                        <div class="error-message" v-if="smtp_errors.host">{{ $t(smtp_errors.host) }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t(`Port`) }}*</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            maxlength="100"
                                            :placeholder="$t(`Port`)"
                                            v-model="smtp.port"
                                        />
                                        <div class="error-message" v-if="smtp_errors.port">{{ $t(smtp_errors.port) }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t(`Username`) }}*</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            maxlength="100"
                                            :placeholder="$t(`username`)"
                                            v-model="smtp.username"
                                        />
                                        <div class="error-message" v-if="smtp_errors.username">{{ $t(smtp_errors.username) }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t(`Password`) }}*</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            maxlength="100"
                                            :placeholder="$t(`Password`)"
                                            v-model="smtp.password"
                                        />
                                        <div class="error-message" v-if="smtp_errors.password">{{ $t(smtp_errors.password) }}</div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-between mt-3">
                                    <button v-if="hasSmtpSettings" type="button" @click="resetSmtpSettings()" class="button danger-button">
                                        {{ $t("Remove SMTP") }}
                                    </button>
                                    <span></span>

                                    <submit-button :click="saveSmtpSettings" :loading="isLoading" >
                                        {{ $t("Submit") }}
                                    </submit-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </dashboard-layout>
</template>

<script>
const DashboardLayout = () => import("../../../layouts/DashboardLayout");
const SettingMenu = () => import( "../../../components/company/SettingSidebar");
import {mapState} from 'vuex';

import client from "../../../app/api/Client";

export default {
    components: {
        DashboardLayout,
        SettingMenu,
    },

    data() {
        return {
            form: {
                sender_email: ''
            },
            isContentLoading: true,
            errors: {
                sender_email: ''
            },
            smtp: {

            },
            smtp_errors: {
                host: '',
                port: '',
                username: '',
                password: ''
            },
            isLoading: false,
            contentLoading: false
        }
    },

    computed: {
        ...mapState(['company', 'user']),

        isEmpty() {
            return this.settings.length === 0;
        },

        hasPackagePermissionRemoteInterview() {
            return this.user.package_rules.remote_interview;
        },

        hasSmtpSettings() {
            return this.smtp.host !== '' || this.smtp.port !== '' || this.smtp.username !== '' || this.smtp.password !== '';
        }
    },
    methods: {
        async saveSenderEmail() {
            this.errors.sender_email = '';

            if(this.hasSmtpSettings && this.form.sender_email.length === 0) {
                this.errors.sender_email = this.$t('Please remove SMTP setting before updating company sender email to default.');
                return false;
            }

            try {
                let {data: {message}} = await client().post(`/company/setting/email/sender-email`, this.form);
                this.$toast.success(this.$t(message));
            } catch ({response: {data: {message}}}) {
                this.errors.sender_email = message.sender_email[0] ?? '';
            }
        },

        async getSenderEmail() {
            try {
                let {data: {data}} = await client().get(`/company/setting/email/sender-email`);
                this.form.sender_email = data.sender_email;
                this.smtp = data.smtp;
                this.contentLoading = true;
            } catch (error) {
                this.$toast.error(error.response.data.message);
            }
        },

        async resetSmtpSettings() {

            let message = {
                title : this.$t(`Confirmation`),
                body : this.$t(`Are you sure, you want to remove this SMTP setting?`)
            };
            let dialogConfig = {
                okText: this.$t('yes'),
                cancelText: this.$t('no'),
            };
            this.$dialog.confirm(message, dialogConfig).then(async () => {
                try {
                    this.resetSmtpForm();

                    let {data: {message}} = await client().post(`/company/setting/email/reset-smtp-settings`);
                    this.$toast.success(this.$t(message));
                } catch (error) {
                    this.$toast.error(error.response.data.message);
                }
            });
        },

        async saveSmtpSettings() {
            this.setSmtpErrorsEmpty();
            if(this.form.sender_email === '') {
                this.errors.sender_email = this.$t('Sender email is required');
                this.$toast.error( this.$t('Sender email is required'));
                return;
            }

            this.isLoading = true;
            try {
                let {data: {message}} = await client().post(`/company/setting/email/smtp-settings`, this.smtp);
                this.$toast.success(this.$t(message));
            } catch (error) {
                if (error.response.status === 422) {
                    this.smtp_errors = _helper.serializeValidationMessage(error.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(error.response.data.message);
                }
            } finally {
                this.isLoading = false;
            }
        },

        setSmtpErrorsEmpty() {
            this.smtp_errors.host = '';
            this.smtp_errors.port = '';
            this.smtp_errors.username = '';
            this.smtp_errors.password = '';
        },

        resetSmtpForm() {
            this.smtp.host = '';
            this.smtp.port = '';
            this.smtp.username = '';
            this.smtp.password = '';
        }
    },
    mounted() {
        this.getSenderEmail();
    }
}
</script>

<style scoped>
/* Css for notify button */

@media all and (max-width: 990px) {
    .feature-image-box > .row {
        margin-bottom: 10px !important;
    }
}

@media (max-width: 767.98px) {
    .control-button span, .control-button--primary span {
        display: block;
    }
}
.form-box .form-group .form-note pre {
    display: unset;
    color: #ff5f74;
    margin-bottom: 0;
}

</style>
